<template>
  <h1 style="color: black;"> Contact Us</h1>
  <h5 style="color: black;"> Once a request is received, please allow 1 to 2 business days for us to respond.</h5>
  <hr/>
  <div class="row" style="text-align: left">
    <div class="col-2"></div>
    <div class="col" style="margin-right: 50px; margin-top: 50px;">
      <h5 style="color: black;">Questions?</h5>
      <h5 style="color: black;">We want to know what you have to say and we look forward to hearing
        from you!</h5>
      <h5 style="color: black;"> If you need help, you can request help directly by clicking the button!</h5>
    </div>
    <div class="col-2"></div>

  </div>
  <div class="row">
    <div class="col" style="text-align: center">
      <a
          class="button btn btn-primary"
          role="button"
          target="_blank"
          @click="helpButtonClicked"
      >Click Here</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "RequestHelpV2",
  setup() {
    function helpButtonClicked() {
      window.open('https://nebraska.jitbit.com/helpdesk/Tickets/New?categoryId=513886', 'pagename', 'resizeable,height=700,width=1000');
      return false;
    }

    return {
      helpButtonClicked
    }
  },
};
</script>

<style scoped>
</style>